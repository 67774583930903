<div class="bg-white py-5 px-2 flex flex-col gap-6">
  <h3 class="text-center text-xl text-primary">Ma clé d'API</h3>
  <div class="flex justify-between">
    <div class="flex flex-col">
      <div class="flex items-center">
        <label>Clé API</label>
        <input
          type="text"
          class="form-control mx-2 rounded border-gray-200"
          [value]="'...' + (license.accessKey | slice: 4:20) + '...'"
          disabled
        />
        <div class="relative w-fit">
          <button
            class="rounded border border-gray-200 p-2 hover:bg-gray-200"
            (click)="copyMessage(license.accessKey)"
          >
            Copier
          </button>
        </div>
      </div>
      <div>
        <span *ngIf="isCopied" id="copied-notification" class="text-primary text-sm py-4 text-center">Vous avez copié la clé !</span>
      </div>
    </div>
    <div>
      <p>
        Nombre de requêtes restants :
        <span class="text-primary font-bold">{{
          license.numberOfQueries
          }}</span>
        <br/>
        Expire dans :
        <span class="text-primary font-bold">{{
          getNumberOfDaysBeforeExpiration()
          }}</span>
        jour(s) | ({{ license.endDate | date: "d/M/Y" }})
      </p>
    </div>
  </div>
</div>
