<div class="w-full px-4 pt-6 sm:px-6 lg:px-8 py-6">
  <ngx-loading [show]="isLoading"></ngx-loading>

  <div
    *ngIf="!this.isAccountActive && !this.loadingComponent"
    class="text-red-500 font-bold bg-red-100 px-5 py-2 my-3"
  >
    Pour utiliser la console il faut un compte actif.
  </div>
  <div
    *ngIf="this.isAccountActive && !this.loadingComponent"
    class="w-full mt-8 flex flex-col bg-white shadow rounded p-4"
  >
    <form
      [formGroup]="form"
      enctype="multipart/form-data"
      (ngSubmit)="sendRequest()"
    >
      <div class="w-full flex flex-col my-4">
        <header class="w-full">
          <div class="flex items-center">
            <div
              class="w-8 h-8 bg-primary text-white rounded flex items-center justify-center mr-4"
            >
              1
            </div>
            <h3 class="font-bold">Choisissez votre OCR</h3>
          </div>
        </header>
        <main
          class="mt-4 w-full flex flex-col justify-center items-center py-4" id="products-list"
        >
          <div class="flex justify-center items-center w-full max-w-screen-sm">
            <div
              *ngFor="let product of products"
              class="cursor-pointer flex justify-center items-center rounded px-4 py-2 mx-2"
            >
              <input
                (change)="selectProduct(product); checkInput()"
                class="hidden"
                id="{{ product.id }}"
                type="radio"
                name="api"
                [checked]="product.id == queryParam"
              />
              <label
                class="flex hover:border-primary shadow rounded px-4 py-2 mx-2 border border-white border-solid justify-center items-center cursor-pointer"
                for="{{ product.id }}"
              >
                <!-- Note: À corriger dés que le file service est opérationnel -->
                <!-- <img
                  [src]="product.logo"
                  [alt]="product.description"
                  class="w-16 h-16"
                /> -->

                <img
                  [src]="
                    product.logo
                      ? resourceUrl + product.logo
                      : '/../../../../assets/logos/logo.jpg'
                  "
                  alt="logo"
                  class="w-16 h-16"
                />
                <h3>{{ product.name }}</h3>
              </label>
            </div>
          </div>
          <!-- To replace with a versions list !!  -->
          <!-- <div
            *ngIf="selectedProduct != null || queryParam != null"
            class="flex justify-center items-center w-full max-w-screen-sm"
          >
            <div
              *ngFor="let api of selectedProduct.productApis"
              class="cursor-pointer flex justify-center items-center rounded px-4 py-2 mx-2"
            >
              <input
                (change)="selectApi(api)"
                class="hidden"
                id="{{ api.id }}"
                type="radio"
                name="product"
              />
              <label
                class="flex flex-col hover:border-primary shadow rounded px-4 py-2 mx-2 border border-white border-solid justify-center items-center cursor-pointer"
                for="{{ api.id }}"
              >
                <h3>Version {{ api.version }}</h3>
              </label>
            </div>
          </div> -->
        </main>
      </div>
      <div class="w-full flex flex-col my-4">
        <header class="w-full">
          <div class="flex items-center">
            <div
              class="w-8 h-8 bg-primary text-white rounded flex items-center justify-center mr-4"
            >
              2
            </div>
            <h3 class="font-bold">Importez vos fichiers</h3>
          </div>
        </header>
      </div>
      <div
        class="flex justify-center items-center w-full" id="file-input"
        appDragAndDrop
        (filesChangeEmiter)="selectFileToUpload($event); checkInput()"
      >
        <label
          for="dropzone-file"
          class="flex flex-col justify-center items-center w-full h-64 bg-blue rounded-lg border border-black border-dashed cursor-pointer dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
        >
          <div class="flex flex-col justify-center items-center pt-5 pb-6">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="w-14 h-14 text-gray-600"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
              />
            </svg>
            <a class="flex bg-primary text-white px-8 py-2 mx-1 my-4">
              {{ selectedFile ? 'Changer/Choisir un autre fichier' : 'Choisir le fichier'}}
            </a>
            <p class="mb-2 text-sm text-gray-500 dark:text-gray-400">
              Ou déposer le ici
            </p>
            <p class="text-xs text-gray-500 dark:text-gray-400">
              PNG, JPG or PDF (MAX. 200MB)
            </p>
            <div *ngIf="this.selectedFile" class="w-full flex flex-row my-4 justify-center">
              <div *ngFor="let file of this.selectedFile">
                <p class="my-1">{{ file.name }}</p>
              </div>
            </div>
          </div>
          <input
            (change)="selectFileToUpload($event);  checkInput()"
            id="dropzone-file"
            type="file"
            class="hidden"
            multiple="true"
          />
        </label>
      </div>
      <div class="w-full flex flex-col my-4">
        <header class="w-full">
          <div class="flex items-center">
            <div
              class="w-8 h-8 bg-primary text-white rounded flex items-center justify-center mr-4"
            >
              3
            </div>
            <h3 class="font-bold">Lancer l’extraction</h3>
          </div>
        </header>
        <!-- <div>
        Product {{ selectedProduct?.name }} file {{ selectFileToUpload }}
        </div> -->
        <main class="mt-4 w-full flex justify-center items-center py-4">
          <button
            type="submit"
            class="bg-dark px-8 py-2 cursor-pointer text-white font-bold disabled:bg-gray-200 disabled:cursor-not-allowed"
          >
            Lancer
          </button>
        </main>
      </div>
    </form>
  </div>
  <div
    *ngIf="this.loadingComponent"
    class="w-full flex justify-center flex-wrap px-4 sm:px-6 md:px-8 mt-6"
  >
    <div role="status">
      <svg
        class="inline mr-2 w-16 h-16 text-gray-200 animate-spin dark:text-primary fill-primary"
        viewBox="0 0 100 101"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
          fill="currentColor"
        />
        <path
          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
          fill="currentFill"
        />
      </svg>
      <span class="sr-only">Loading...</span>
    </div>
  </div>

  <div *ngIf="showMsg === true" id="modal-msg">
    <div *ngIf="requestResponse" class="modal-info-inline p-7">
      <p class="text-xl">{{ requestMessage }}</p>
      <br />
      <p class="text-red-500 text-left">{{ errorDescription }}</p>
      <a
        [routerLink]="['/dashboard/request-result/' + resultId, {}]"
        *ngIf="resultId != null && responseStatus == 'SUCCESS'"
      >
        <button
          class="h-12 px-6 py-2.5 bg-[#339886] text-white font-medium text-lg leading-tight font-bold mt-7 shadow-md hover:bg-[#1d4e4b] hover:shadow-lg focus:bg-[#1d4e4b] focus:shadow-lg focus:outline-none focus:ring-0 active:bg-[#1d4e4b] active:shadow-lg transition duration-150 ease-in-out"
        >
          Voir résultat
        </button>
      </a>
      <a
        (click)="reloadPage()"
        *ngIf="resultId == null || responseStatus == 'FAILED'"
      >
        <button
          class="h-12 px-6 py-2.5 bg-[#339886] text-white font-medium text-lg leading-tight font-bold mt-7 shadow-md hover:bg-[#1d4e4b] hover:shadow-lg focus:bg-[#1d4e4b] focus:shadow-lg focus:outline-none focus:ring-0 active:bg-[#1d4e4b] active:shadow-lg transition duration-150 ease-in-out"
        >
          Fermer
        </button>
      </a>
    </div>
  </div>
</div>
