<div class="py-6">
  <div class="w-full px-4 pt-6 sm:px-6 lg:px-8">
    <div class="sm:flex sm:items-center">
      <div class="sm:flex-auto">
        <h1 class="text-2xl font-semibold text-gray-900">Requêtes</h1>
      </div>
    </div>
    <div class="w-full mt-8 flex flex-col bg-white shadow rounded">
      <div class="w-full my-2 px-2">
        <form class="w-full flex justify-between">
          <div class="flex gap-4">
            <select
              class="capitalize font-bold rounded border-slate-400 text-gray-400"
              name=""
              id=""
              #service
              (change)="filterByService(service.value)"
            >
              <option value="all" selected>Tous les produits</option>
              <option [value]="product.id" *ngFor="let product of products">
                {{ product.name }}
              </option>
            </select>
          </div>
          <div>
            <select
              class="capitalize font-bold rounded border-slate-400 text-gray-400"
              name=""
              id=""
              #inputExport
              (change)="exportResult(inputExport.value)"
            >
              <option value="0" selected disabled>Exporter</option>
              <option value="csv">CSV</option>
              <option value="json">JSON</option>
              <option value="pdf">PDF</option>
            </select>
          </div>
        </form>
      </div>
      <div class="my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <div class="overflow-hidden">
            <table class="min-w-full" id="my-table" *ngIf="!isLoading">
              <thead class="">
                <tr>
                  <th
                    *ngFor="let item of headers"
                    scope="col"
                    class="py-3.5 px-3 text-left text-sm font-bold text-primary"
                  >
                    {{ item }}
                  </th>
                </tr>
              </thead>
              <tbody class="" *ngIf="filteredRequests.length > 0">
                <tr
                  *ngFor="
                    let request of filteredRequests
                  "
                >
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <div
                      class="flex items-center text-gray-900 font-semibold text-left"
                    >
                      <img
                        [src]="
                          request.product.logo
                            ? resourceUrl + request.product.logo
                            : './../../../../../assets/logos/logo.jpg'
                        "
                        alt="logo"
                        class="w-16 h-16"
                      />
                      <p>{{ request.product.name }}</p>
                    </div>
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <div class="text-gray-900 text-center">
                      {{ request.requestDate | date : "dd/M/Y, h:mm a" }}
                    </div>
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <div class="text-gray-900 text-center">
                      {{ request.duration }} ms
                    </div>
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <div
                      *ngIf="request.status == 'SUCCESS'"
                      class="text-white text-center p-2"
                    >
                      <!-- {{ request.status }} -->
                      <img
                        src="../../../../assets/icons/success.jpeg"
                        class=""
                        id="iconStatus"
                        alt="iconStatus"
                      />
                    </div>
                    <div
                      *ngIf="request.status != 'SUCCESS'"
                      class="text-white text-center p-2"
                    >
                      <!-- {{ request.status }} -->
                      <img
                        src="../../../../assets/icons/error-icon-4.png"
                        class=""
                        id="iconStatusError"
                        alt="iconStatusError"
                      />
                    </div>
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <div class="text-gray-900 flex items-center justify-center">
                      <a
                        *ngIf="request.status == 'SUCCESS'"
                        [routerLink]="['/dashboard/request-result', request.id]"
                        class="flex cursor-pointer bg-white text-primary p-2 rounded mx-1 hover:text-gray-800 hover:bg-primary border border-solid border-primary hover:font-bold"
                      >
                        <img
                          src="../../../assets/icons/code-2.svg"
                          alt=""
                          class="w-6 h-6 mx-2"
                        />
                      </a>
                      <!-- <a
                        download="ganeyi.json"
                        [href]="request.requestResult.fileUri"
                        class="flex cursor-pointer bg-primary text-white p-2 rounded mx-1 hover:text-primary hover:bg-white hover:border hover:border-solid hover:border-primary"
                      >
                        Télécharger
                      </a>
                      <img
                        (click)="viewResultDetail(request.requestResult)"
                        src="../../../assets/icons/info.svg"
                        alt=""
                        class="w-6 h-8 ml-2"
                      /> -->
                    </div>
                  </td>
                </tr>
              </tbody>
              <tbody *ngIf="filteredRequests.length == 0">
                <tr>
                  <td colspan="6" class="text-center text-gray-500">
                    Aucun résultat trouvé
                  </td>
                </tr>
              </tbody>
            </table>
            <div *ngIf="!isLoading">
              <app-pagination
                [totalItems]="totalItems"
                [currentPage]="page"
                [pageSize]="pageSize"
                (pageChangeEvent)="onPageChange($event)"
                (pageSizeChangeEvent)="onPageSizeChange($event)"
              ></app-pagination>
            </div>
            <div
              *ngIf="isLoading"
              class="w-full flex justify-center flex-wrap px-4 sm:px-6 md:px-8 mt-6"
            >
              <div role="status">
                <svg
                  class="inline mr-2 w-16 h-16 text-gray-200 animate-spin dark:text-primary fill-primary"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-results-view
  *ngIf="isOpen"
  [result]="result"
  (onClose)="changeModalState($event)"
></app-results-view>
