<div class="bg-gray-100 space-y-6 sm:px-6 lg:col-span-9 lg:px-0">
  <div class="mx-auto my-5 p-5">
    <div class="md:flex no-wrap md:-mx-2">
      <div class="w-full md:w-3/12 md:mx-2">
        <div class="bg-white p-3 border-t-4 border-teal-600">
          <div class="image overflow-hidden">
            <img
              class="h-auto w-full mx-auto"
              src="./../../../../assets/images/user_profile_pic.jpg"
              alt=""
            />
          </div>
          <h1 class="text-gray-900 font-bold text-xl leading-8 my-1">
            {{ user.firstName }} {{ user.lastName | uppercase }}
          </h1>
          <h3 class="text-gray-600 font-lg text-semibold leading-6">
            Entreprise: {{ this.entreprise }}
          </h3>
          <!-- <h3 class="text-gray-600 font-lg text-semibold leading-6">Secteur: {{user.secteur}} </h3> -->
        </div>
      </div>
      <div class="w-full md:w-9/12 mx-2 h-64">
        <div class="bg-white p-3 shadow-sm rounded-sm">
          <div
            class="flex items-center space-x-2 font-semibold text-gray-900 leading-8"
          >
            <span clas="text-green-500">
              <svg
                class="h-5"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                />
              </svg>
            </span>
            <span class="tracking-wide">Informations personnelles</span>
          </div>
          <div class="text-gray-700">
            <div class="grid md:grid-cols-2 text-sm">
              <div class="grid grid-cols-2">
                <div class="pr-1 py-2 font-semibold">Prénom</div>
                <div class="px-2 py-2 break-all">{{ user.firstName }}</div>
              </div>
              <div class="grid grid-cols-2">
                <div class="pr-1 py-2 font-semibold">Nom</div>
                <div class="px-2 py-2 break-all">{{ user.lastName }}</div>
              </div>
              <div class="grid grid-cols-2">
                <div class="pr-1 py-2 font-semibold">Adresse</div>
                <div class="px-2 py-2 break-all">{{ user.address }}</div>
              </div>
              <div class="grid grid-cols-2">
                <div class="pr-1 py-2 font-semibold">Téléphone</div>
                <div class="px-2 py-2 break-all">{{ user.phoneNumber }}</div>
              </div>
              <div class="grid grid-cols-2">
                <div class="pr-1 py-2 font-semibold">Email</div>
                <div class="px-2 py-2 break-all">
                  <a class="text-blue-800" href="mailto:{{ user.email }}">{{
                    user.email
                  }}</a>
                </div>
              </div>
              <div class="grid grid-cols-2">
                <div class="pr-1 py-2 font-semibold">Pays</div>
                <div class="px-2 py-2 break-all">{{ user.country }}</div>
              </div>
              <div class="grid grid-cols-2">
                <div class="pr-1 py-2 font-semibold">Ville</div>
                <div class="px-2 py-2 break-all">{{ user.city }}</div>
              </div>
            </div>
          </div>
          <div class="my-3">
            <a routerLink="/dashboard/settings/account">
              <button
                class="flex disabled:text-gray-500 disabled:bg-gray-200 disabled:hover:bg-gray-200 disabled:hover:border-0 disabled:hover:text-gray-500 bg-primary text-white px-5 py-2"
              >
                Modifier mes informations
              </button>
            </a>
          </div>
        </div>

        <div class="my-4"></div>

        <div class="bg-white p-3 shadow-sm rounded-sm">
          <div
            class="flex items-center space-x-2 font-semibold text-gray-900 leading-8 mb-3"
          >
            <span clas="text-green-500">
              <svg
                class="h-5"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                />
              </svg>
            </span>
            <span class="tracking-wide">Mes produits</span>
          </div>

          <div *ngIf="products" class="w-full mt-6">
            <ul
              *ngFor="let produit of products"
              class="list-inside space-y-2 my-3"
            >
              <li>
                <div class="text-teal-600">{{ produit.name | uppercase }}</div>
                <div class="text-gray-500 text-xs">
                  {{ produit.description }}
                </div>
              </li>
            </ul>
            <div>
              <a routerLink="/dashboard/products">
                <button
                  class="flex disabled:text-gray-500 disabled:bg-gray-200 disabled:hover:bg-gray-200 disabled:hover:border-0 disabled:hover:text-gray-500 bg-primary text-white px-5 py-2"
                >
                  Voir tous les produits
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
