<ngx-loading [show]="loading"></ngx-loading>

<main
  class="flex flex-row justify-center items-center min-h-screen w-screen p-3 py-16 lg:h-screen lg:w-screen"
>
  <div
    id="left_part"
    class="flex flex-col justify-center items-center w-full lg:w-1/2"
  >
    <a href="#">
      <img
        src="./../../../assets/logos/LOGO_GANEYI_blanc.png"
        alt="Logo Ganeyi blanc"
        width="100"
      />
    </a>
    <h1 class="text-2xl w-fit h-fit my-10 text-4xl text-white font-semibold">
      Réinitialiser votre mot de passe
    </h1>
    <div class="flex flex-col justify-center items-center w-full h-fit">
      <form
        class="w-full sm:w-2/3"
        [formGroup]="resetPasswordForm"
        (ngSubmit)="submitNewPassword()"
      >
        <input
          class="w-full h-12 my-3 rounded-md bg-transparent border-white/50 text-white transition"
          type="password"
          formControlName="newPassword"
          id="new-password"
          (keyup)="verifyInputs(); checkNewPassword()"
          placeholder="Nouveau mot de passe"
        />
        <input
          class="w-full h-12 my-3 rounded-md bg-transparent border-white/50 text-white transition"
          type="password"
          id="confirm-password"
          placeholder="Confirmer mot de passe"
          (keyup)="verifyInputs(); checkNewPassword()"
          formControlName="confirmPassword"
        />

        <ul
          *ngIf="isSubmitted && (!isInputsValid || changePasswordMsg != '')"
          class="w-full text-red-500 font-bold bg-red-100 px-5 py-2 text-lg my-3"
        >
          Attention:
          <li *ngIf="!isInputsValid">Certains champs ne sont pas remplis</li>
          <li *ngIf="changePasswordMsg != ''">{{ changePasswordMsg }}</li>
        </ul>

        <button
          disabled
          type="submit"
          id="reset-password-btn"
          class="w-full h-12 px-6 py-2.5 bg-slate-50 text-[#339682] font-medium text-lg leading-tight font-bold mt-7 shadow-md hover:bg-slate-200 hover:shadow-lg focus:bg-slate-200 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-slate-200 active:shadow-lg transition duration-150 ease-in-out"
        >
          Envoyer
        </button>
        <div class="w-full flex justify-start my-4">
          <span class="text-slate-300 mr-3">Je n'ai pas de compte ?</span>
          <a
            class="text-dark underline cursor-pointer"
            [routerLink]="['/signup', {}]"
            >Je m'inscris</a
          >
        </div>
      </form>
    </div>
  </div>
  <div
    id="right_part"
    class="relative flex flex-col justify-center items-center w-1/2 hidden lg:flex h-4/5"
  >
    <img
      id="spectre"
      class="absolute inset-0 w-full h-full"
      src="./../../../assets/logos/spectre_bg.svg"
      alt="Spectre"
    />
    <img
      class="w-1/5"
      src="./../../../assets/logos/logo_ganeyi.png"
      alt="Logo Ganeyi"
    />
  </div>
</main>

<div *ngIf="showMsg === true" id="modal-msg">
  <div *ngIf="isRequestSent == false" class="modal-info-inline p-7">
    <h2 class="text-2xl my-4">Oups !</h2>
    <p class="text-xl">
      Quelque chose c'est mal passé. Veuillez vérifier votre connexion ou
      réesayer plus tard.
    </p>

    <button
      (click)="switchModal()"
      routerLink="/auth"
      class="h-12 px-6 py-2.5 bg-[#339886] text-white font-medium text-lg leading-tight font-bold mt-7 shadow-md hover:bg-[#1d4e4b] hover:shadow-lg focus:bg-[#1d4e4b] focus:shadow-lg focus:outline-none focus:ring-0 active:bg-[#1d4e4b] active:shadow-lg transition duration-150 ease-in-out"
    >
      Fermer
    </button>
  </div>

  <div *ngIf="codeExpired" class="modal-info-inline p-7">
    <h2 class="text-2xl my-4">Oups !</h2>
    <p class="text-xl">
      Le lien de reset password a expiré; Veuillez recommencer
    </p>

    <button
      (click)="switchModal()"
      routerLink="/auth/forgot-password"
      class="h-12 px-6 py-2.5 bg-[#339886] text-white font-medium text-lg leading-tight font-bold mt-7 shadow-md hover:bg-[#1d4e4b] hover:shadow-lg focus:bg-[#1d4e4b] focus:shadow-lg focus:outline-none focus:ring-0 active:bg-[#1d4e4b] active:shadow-lg transition duration-150 ease-in-out"
    >
      Fermer
    </button>
  </div>

  <app-modal-msg *ngIf="isRequestSent" [message]="successMessage" [redirectionUrl]="'/auth'"></app-modal-msg>
</div>
