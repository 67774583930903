<main
  class="flex flex-row justify-center items-center min-h-screen w-screen p-3 py-16 lg:h-screen lg:w-screen"
>
  <div
    id="left_part"
    class="flex flex-col justify-center items-center w-full lg:w-1/2"
  >
    <a href="#">
      <img
        src="./../../../../assets/logos/LOGO_GANEYI_blanc.png"
        alt="Logo Ganeyi blanc"
        width="100"
      />
    </a>
    <h1 class="text-2xl w-fit h-fit my-10 text-4xl text-white font-semibold">
      Mot de passe oublié
    </h1>
    <div class="flex flex-col justify-center items-center w-full h-fit">
      <form
        class="w-full sm:w-2/3"
        [formGroup]="forgotPasswordForm"
        (ngSubmit)="onSubmit()"
      >
        <div
          *ngIf="error"
          class="bg-red-200 text-red-700 mt-3 p-5 justify-center items-center rounded mb-0"
        >
          {{ error.toString() }}
        </div>
        <input
          class="w-full h-12 my-3 rounded-md bg-transparent border-white/50 text-white hover:border-white focus:border-white transition"
          type="email"
          formControlName="email"
          placeholder="Renseignez votre Email"
        />
        <div *ngIf="submitted && f['email'].errors" class="invalid-feedback">
          <div *ngIf="f['email'].errors['required']">Email invalid</div>
        </div>
        <button
          [disabled]="loading"
          type="submit"
          class="w-full h-12 px-6 py-2.5 bg-slate-50 text-[#339682] font-medium text-lg leading-tight font-bold mt-7 shadow-md hover:bg-slate-200 hover:shadow-lg focus:bg-slate-200 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-slate-200 active:shadow-lg transition duration-150 ease-in-out"
        >
          <span
            *ngIf="loading"
            class="spinner-border spinner-border-sm mr-1"
          ></span>
          Envoyer
        </button>
        <div class="w-full flex justify-start my-4">
          <span class="text-slate-300 mr-3">J'ai mon mot de passe ?</span>
          <a
            class="text-dark underline cursor-pointer"
            [routerLink]="['/auth/signin', {}]"
            >Je me connecte</a
          >
        </div>
      </form>
    </div>
  </div>
  <div
    id="right_part"
    class="relative flex flex-col justify-center items-center w-1/2 hidden lg:flex h-4/5"
  >
    <img
      id="spectre"
      class="absolute inset-0 w-full h-full"
      src="./../../../../assets/logos/spectre_bg.svg"
      alt="Spectre"
    />
    <img
      class="w-1/5"
      src="./../../../../assets/logos/logo_ganeyi.png"
      alt="Logo Ganeyi"
    />
  </div>
  <app-modal-msg *ngIf="successMessage != ''" [message]="successMessage" [redirectionUrl]="'/auth'"></app-modal-msg>
</main>
