<div class="space-y-6 sm:px-6 lg:col-span-9 lg:px-0">
  <form [formGroup]="changePasswordForm" (ngSubmit)="submitNewPassword()" class="w-full">
    <div class="shadow sm:overflow-hidden sm:rounded-md">
      <div class="space-y-6 bg-white py-6 px-4 sm:p-6">
        <div>
          <h3 class="text-lg font-medium leading-6 text-gray-900">
            Modifier mon mot de passe
          </h3>
        </div>

        <div *ngIf="isSubmitted && !hasError" id="modal-info">
          <div class="modal-info-inline p-7">
            <p *ngIf="this.infoUpdated === 'password'" class="text-xl">
              Votre nouveau mot de passe a été enregistré. Veuillez vous
              reconnecter.
            </p>

            <a (click)="logout()">
              <button
                class="h-12 px-6 py-2.5 bg-[#339886] text-white font-medium text-lg leading-tight font-bold mt-7 shadow-md hover:bg-[#1d4e4b] hover:shadow-lg focus:bg-[#1d4e4b] focus:shadow-lg focus:outline-none focus:ring-0 active:bg-[#1d4e4b] active:shadow-lg transition duration-150 ease-in-out">
                Se reconnecter
              </button>
            </a>
          </div>
        </div>

        <ul *ngIf="hasError" class="w-full text-red-500 font-bold bg-red-100 my-3 text-lg">
          <span class=" px-5 py-2 " *ngIf="!isInputsValid || changePasswordMsg != ''">Attention:</span>
          <li *ngIf="!isInputsValid" class=" px-5 py-1 ">Certains champs ne sont pas remplis</li>
          <li *ngIf="changePasswordMsg != ''" class=" px-5 py-1 ">{{ changePasswordMsg }}</li>
        </ul>

        <div class="grid grid-cols-6 gap-6">
          <div class="col-span-6 sm:col-span-4">
            <label for="old-password" class="block text-sm font-medium text-gray-700">Ancien mot de passe</label>
            <input type="password" id="old-password" placeholder="******************" formControlName="oldPassword"
              class="mt-1 block w-full rounded-md border py-2 px-3 shadow-sm focus:border-primary focus:outline-none focus:ring-primary sm:text-sm" />
          </div>

          <div class="col-span-6 sm:col-span-4">
            <label for="new-password" class="block text-sm font-medium text-gray-700">Nouveau mot de passe</label>
            <input type="password" id="new-password" type="password" placeholder="******************"
              formControlName="newPassword" (keyup)="checkNewPassword()" (change)="checkNewPassword()"
              class="mt-1 block w-full rounded-md border py-2 px-3 shadow-sm focus:border-primary focus:outline-none focus:ring-primary sm:text-sm" />
          </div>

          <div class="col-span-6 sm:col-span-4">
            <label for="confirm-password" class="block text-sm font-medium text-gray-700">Confirmer le nouveau mot de
              passe</label>
            <input type="password" id="confirm-password" type="password" placeholder="******************"
              formControlName="confirmPassword" (keyup)="checkNewPassword()" (change)="checkNewPassword()"
              class="mt-1 block w-full rounded-md border py-2 px-3 shadow-sm focus:border-[primary] focus:outline-none focus:ring-primary sm:text-sm" />
          </div>
        </div>
      </div>
      <div class="bg-gray-50 px-4 py-3 text-right sm:px-6">
        <button type="submit"
          class="inline-flex justify-center rounded-md border border-transparent bg-primary py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
          id="change-password-btn" disabled>
          Sauvegarder
        </button>
      </div>
    </div>
  </form>
</div>