<div class="py-6">
  <div class="px-4 pt-6 sm:px-6 lg:px-8">
    <div class="sm:flex sm:items-center">
      <div class="sm:flex-auto">
        <h1 class="text-2xl font-semibold text-gray-900">
          Résultat Requête
          <span class="p-2 bg-primary text-white rounded text-sm">{{
            request?.status
          }}</span>
        </h1>
      </div>
    </div>
    <div class="w-full my-4">
      <form class="w-full flex justify-between">
        <select
          class="capitalize font-bold rounded border-slate-400 text-gray-400"
          name=""
          id=""
          #inputExport
          (change)="exportResult(inputExport.value)"
        >
          <option value="0" selected>Exporter</option>
          <option value="csv">CSV</option>
          <option value="json">JSON</option>
          <option value="pdf">PDF</option>
        </select>
        <input
          type="text"
          class="capitalize font-bold rounded border-slate-400 text-gray-400"
          placeholder="Rechercher"
          #inputProduct
        />
      </form>
    </div>
    <div class="w-full my-4 flex gap-4 justify-center items-center">
      <div
        class="w-1/4 shadow-lg shadow-gray-500 p-4 flex flex-col justify-center items-center"
      >
        <label class="font-bold text-center">Date</label>
        <p>{{ request?.requestDate | date : "dd/M/Y H:m:s" }}</p>
      </div>
      <div
        class="w-1/4 shadow-lg shadow-gray-500 p-4 flex flex-col justify-center items-center"
      >
        <label class="font-bold text-center">Produit</label>
        <p>{{ request?.productApi?.product?.name }}</p>
      </div>
      <div
        class="w-1/4 shadow-lg shadow-gray-500 p-4 flex flex-col justify-center items-center"
      >
        <label class="font-bold text-center">API</label>
        <p>V{{ request?.productApi?.version }}</p>
      </div>
      <div
        class="w-1/4 shadow-lg shadow-gray-500 p-4 flex flex-col justify-center items-center"
      >
        <label class="font-bold text-center">Durée</label>
        <p>{{ request?.duration }} ms</p>
      </div>
    </div>
  </div>

  <div class="px-4 pt-6 sm:px-6 lg:px-8 flex flex-col gap-4">
    <div
      class="grid grid-cols-2 gap-3 bg-white py-6 px-4"
      *ngIf="request.requestResult.jsonValue.jsonFinal.personal_infos != null"
    >
      <div
        *ngFor="
          let item of request.requestResult.jsonValue.jsonFinal.personal_infos
            | keyvalue
        "
      >
        <div *ngIf="item.key != 'face'" class="flex gap-4">
          <div class="text-primary font-semibold">{{ item.key }}</div>
          <div>{{ item.value }}</div>
        </div>
        <div *ngIf="item.key == 'face'" class="flex gap-4">
          <div class="text-primary font-semibold">{{ item.key }}</div>
          <img [src]="retrieveBase64Image(item.value)" alt="ID Face" />
        </div>
      </div>
    </div>
    <div
      class="grid grid-cols-2 gap-3 bg-white py-6 px-4"
      *ngIf="request.requestResult.jsonValue.jsonFinal.bill_infos != null"
    >
      <div
        *ngFor="
          let item of request.requestResult.jsonValue.jsonFinal.bill_infos
            | keyvalue
        "
      >
        <div class="flex gap-4">
          <div class="text-primary font-semibold">{{ item.key }}</div>
          <div>{{ item.value }}</div>
        </div>
      </div>
    </div>
    <!--  -->
    <table
      class="w-full text-sm text-left"
      id="jstable"
      *ngIf="request?.requestResult?.jsonValue.jsonFinal.tables != null"
    >
      <thead class="border border-primary">
        <tr>
          <th
            *ngFor="let d of bankData.filteredKeys"
            scope="col"
            class="text-left border-r border-primary p-2"
          >
            {{ d }}
          </th>
        </tr>
      </thead>
      <tbody *ngIf="request.requestResult.jsonValue.jsonFinal.type != null">
        <tr
          *ngFor="let ops of bankData.filteredOperations"
          class="border-b border-primary"
        >
          <td
            *ngFor="let data of ops"
            class="text-left p-2 border-x border-primary"
          >
            {{ data }}
          </td>
        </tr>
        <tr
          class="border-b border-primary"
          *ngFor="
            let total of request.requestResult.jsonValue.jsonFinal.totaux
              | keyvalue
          "
        >
          <td colspan="3" class="text-left p-2 border-x border-primary">
            {{ total.key }}
          </td>
          <td colspan="3" class="text-right p-2 border-x border-primary">
            {{ total.value }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
