<div class="w-full px-4 pt-6 sm:px-6 lg:px-8 py-6">
    <ngx-loading [show]="isLoading"></ngx-loading>
    <div *ngIf="this.isAccountActive && !this.loadingComponent" class="w-full p-0 m-0">
        <form [formGroup]="newAgentForm" (ngSubmit)="createNewAgent()">
            <div class="shadow sm:overflow-hidden sm:rounded-md">
                <div class="space-y-6 bg-white py-6 px-4 sm:p-6">
                    <div>
                        <h3 class="text-lg font-medium leading-6 text-gray-900">
                            Ajouter un utilisateur
                        </h3>
                    </div>

                    <div class="grid grid-cols-6 gap-6">
                        <div class="col-span-6 sm:col-span-3">
                            <label for="firstName" class="block text-sm font-medium text-gray-700">Prénom</label>
                            <input type="text" name="first-name" id="firstName" formControlName="firstName" #firstName
                                (keyup)="this.try > 0 ? verifyInputs() : ''"
                                class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-primary focus:outline-none focus:ring-primary sm:text-sm" />
                        </div>

                        <div class="col-span-6 sm:col-span-3">
                            <label for="lastName" class="block text-sm font-medium text-gray-700">Nom</label>
                            <input type="text" name="last-name" id="lastName" formControlName="lastName" #lastName
                                (keyup)="this.try > 0 ? verifyInputs() : ''"
                                class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-primary focus:outline-none focus:ring-primary sm:text-sm" />
                        </div>

                        <div class="col-span-6 sm:col-span-3">
                            <label for="email" class="block text-sm font-medium text-gray-700">Email</label>
                            <input type="email" name="email" id="email" formControlName="email" #email
                                (keyup)="this.try > 0 ? verifyInputs() : ''"
                                class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-primary focus:outline-none focus:ring-primary sm:text-sm" />
                        </div>

                        <div class="col-span-6 sm:col-span-3">
                            <label for="phone" class="block text-sm font-medium text-gray-700">Téléphone</label>
                            <input type="tel" name="phone" id="phone" formControlName="phoneNumber" #phoneNumber
                                (keyup)="this.try > 0 ? verifyInputs() : ''"
                                class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-primary focus:outline-none focus:ring-primary sm:text-sm" />
                        </div>
                    </div>
                </div>
                <div class="bg-gray-50 px-4 py-3 text-right sm:px-6">
                    <div *ngIf="error !== ''"
                        class="bg-red-200 text-red-700 my-3 p-5 justify-center items-center rounded">
                        {{ this.error }}
                    </div>
                    <div *ngIf="success !== ''"
                        class="bg-green-200 text-green-700 my-3 p-5 justify-center items-center rounded">
                        {{ this.success }}
                    </div>

                    <div *ngIf="this.createLoader" class="w-full flex justify-end flex-wrap px-4 sm:px-6 md:p-2 m-2">
                        <div role="status">
                            <svg class="inline m-2 w-8 h-8 text-gray-200 animate-spin dark:text-primary fill-primary"
                                viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                    fill="currentColor" />
                                <path
                                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                    fill="currentFill" />
                            </svg>
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                    <button *ngIf="!this.createLoader" type="submit"
                        class="inline-flex justify-center rounded-md border border-transparent bg-primary py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2">
                        Ajouter
                    </button>
                </div>
            </div>
        </form>

        <div class="flex flex-col">
            <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div class="py-4 inline-block min-w-full sm:px-6 lg:px-8">
                    <div class="overflow-hidden">
                        <table class="min-w-full text-center">
                            <thead class="border-b bg-primary">
                                <tr>
                                    <th scope="col" class="text-sm font-medium text-white px-6 py-4">
                                        Prénom
                                    </th>
                                    <th scope="col" class="text-sm font-medium text-white px-6 py-4">
                                        Nom
                                    </th>
                                    <th scope="col" class="text-sm font-medium text-white px-6 py-4">
                                        Téléphone
                                    </th>
                                    <th scope="col" class="text-sm font-medium text-white px-6 py-4">
                                        Email
                                    </th>
                                    <th scope="col" class="text-sm font-medium text-white px-6 py-4">
                                        Activation
                                    </th>
                                    <th scope="col" class="text-sm font-medium text-white px-6 py-4">
                                        Actions
                                    </th>
                                </tr>
                            </thead>

                            <tbody *ngFor="let agent of this.agents">
                                <tr class="bg-white border-b">
                                    <td class="text-sm text-gray-900 font-light px-2 py-2 whitespace-nowrap">
                                        {{agent.firstName}}
                                    </td>
                                    <td class="text-sm text-gray-900 font-light px-2 py-2 whitespace-nowrap">
                                        {{agent.lastName}}
                                    </td>
                                    <td class="text-sm text-gray-900 font-light px-2 py-2 whitespace-nowrap">
                                        {{agent.phoneNumber}}
                                    </td>
                                    <td class="text-sm text-gray-900 font-light px-2 py-2 whitespace-nowrap">
                                        {{agent.email}}
                                    </td>
                                    <td class="text-sm text-gray-900 font-light px-2 py-2 whitespace-nowrap flex justify-center mt-2">
                                        <form [formGroup]="toggleAgentForm" class="">
                                            <div>
                                                <label class="switch">
                                                    <input type="checkbox"
                                                        [checked]="agent.isActive"
                                                        (change)="toggleAgentAccount(agent, $event)">
                                                    <span class="slider round"></span>
                                                </label>
                                            </div>
                                        </form>

                                    </td>
                                    <td class="text-sm text-gray-900 font-light px-2 py-2 whitespace-nowrap">
                                        <div class="flex justify-center aligns-center h-full w-full">
                                            <button (click)="toggleEditModal(agent)" type="button"
                                                class="inline-flex justify-center rounded-md border border-transparent bg-primary p-2 m-1 text-sm font-medium text-white shadow-sm hover:bg-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2">
                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                                    width="16px" height="16px" viewBox="0 0 330 330"
                                                    style="enable-background:new 0 0 330 330;" xml:space="preserve">
                                                    <g id="XMLID_23_">
                                                        <path id="XMLID_24_" d="M75,180v60c0,8.284,6.716,15,15,15h60c3.978,0,7.793-1.581,10.606-4.394l164.999-165
           c5.858-5.858,5.858-15.355,0-21.213l-60-60C262.794,1.581,258.978,0,255,0s-7.794,1.581-10.606,4.394l-165,165
           C76.58,172.206,75,176.022,75,180z M105,186.213L255,36.213L293.787,75l-150,150H105V186.213z"
                                                            fill="#ffffff" />
                                                        <path id="XMLID_27_"
                                                            d="M315,150.001c-8.284,0-15,6.716-15,15V300H30V30H165c8.284,0,15-6.716,15-15s-6.716-15-15-15H15
           C6.716,0,0,6.716,0,15v300c0,8.284,6.716,15,15,15h300c8.284,0,15-6.716,15-15V165.001C330,156.716,323.284,150.001,315,150.001z"
                                                            fill="#ffffff" />
                                                    </g>
                                                    <g>
                                                    </g>
                                                    <g>
                                                    </g>
                                                    <g>
                                                    </g>
                                                    <g>
                                                    </g>
                                                    <g>
                                                    </g>
                                                    <g>
                                                    </g>
                                                    <g>
                                                    </g>
                                                    <g>
                                                    </g>
                                                    <g>
                                                    </g>
                                                    <g>
                                                    </g>
                                                    <g>
                                                    </g>
                                                    <g>
                                                    </g>
                                                    <g>
                                                    </g>
                                                    <g>
                                                    </g>
                                                    <g>
                                                    </g>
                                                </svg>
                                            </button>
                                            <button type="button" (click)="toggleDeleteModal(agent)"
                                                class="inline-flex justify-center rounded-md border border-transparent bg-red-500 p-2 m-1 text-sm font-medium text-white shadow-sm hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2">
                                                <svg width="16px" height="16px" viewBox="0 0 16 16"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path fill="#ffffff" fill-rule="evenodd"
                                                        d="M5,2 C5,0.895431 5.89543,0 7,0 L9,0 C10.1046,0 11,0.895431 11,2 L11,3 L14,3 C14.5523,3 15,3.44772 15,4 C15,4.55228 14.5523,5 14,5 L13.8462,5 L13.142,14.1534 C13.0619,15.1954 12.193,16 11.1479,16 L4.85206,16 C3.80699,16 2.93811,15.1954 2.85795,14.1534 L2.15385,5 L2,5 C1.44772,5 1,4.55228 1,4 C1,3.44772 1.44772,3 2,3 L5,3 L5,2 Z M7,3 L9,3 L9,2 L7,2 L7,3 Z M4.15975,5 L4.85206,14 L11.1479,14 L11.8402,5 L4.15975,5 Z" />
                                                </svg>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="this.loadingComponent" class="w-full flex justify-center flex-wrap px-4 sm:px-6 md:px-8 mt-6">
        <div role="status">
            <svg class="inline mr-2 w-16 h-16 text-gray-200 animate-spin dark:text-primary fill-primary"
                viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor" />
                <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill" />
            </svg>
            <span class="sr-only">Loading...</span>
        </div>
    </div>

    <div *ngIf="!this.isAccountActive && !this.loadingComponent"
        class="bg-red-200 text-red-700 my-3 p-5 justify-center items-center rounded">
        Il vous faut compte actif pour accéder à cette partie.
    </div>
</div>

<div *ngIf="deleteModal || editModal" id="modal-msg">

    <div *ngIf="editModal" class="modal-info-inline modal-agent-update">

        <form [formGroup]="updateAgentForm" (ngSubmit)="updateAgent()">
            <div class="shadow sm:overflow-hidden sm:rounded-md">
                <div class="space-y-6 bg-white py-6 px-4 sm:p-6">
                    <h2 class="text-2xl my-4">Modifier les informations de l'utilisateur</h2>

                    <div class="grid grid-cols-6 gap-6">
                        <div class="col-span-6 sm:col-span-3">
                            <label for="first-name" class="block text-lg font-medium text-gray-700">Prénom</label>
                            <input type="text" name="firstName" id="first-name" formControlName="firstName"
                                class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-primary focus:outline-none focus:ring-primary sm:text-sm" />
                        </div>

                        <div class="col-span-6 sm:col-span-3">
                            <label for="last-name" class="block text-lg font-medium text-gray-700">Nom</label>
                            <input type="text" name="lastName" id="last-name" formControlName="lastName"
                                class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-primary focus:outline-none focus:ring-primary sm:text-sm" />
                        </div>

                        <div class="col-span-6 sm:col-span-3">
                            <label for="phone" class="block text-lg font-medium text-gray-700">Téléphone</label>
                            <input type="tel" name="phoneNumber" id="phone" formControlName="phoneNumber"
                                class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-primary focus:outline-none focus:ring-primary sm:text-sm" />
                        </div>
                    </div>
                </div>
                <div class="bg-gray-50 px-4 py-3 text-right sm:px-6">

                    <div *ngIf="errorModal != ''"
                        class="bg-red-200 text-red-700 mt-3 p-5 justify-center items-center rounded mb-0">
                        {{ this.errorModal }}
                    </div>
                    <br>
                    <div *ngIf="successModal != ''"
                        class="bg-green-200 text-green-700 my-3 p-5 justify-center items-center rounded">
                        {{ this.successModal }}
                    </div>
                    <br>
                    <div *ngIf="successModal === ''">
                        <div *ngIf="this.updateLoader"
                            class="w-full flex justify-center flex-wrap px-4 sm:px-6 md:p-2 m-2">
                            <div role="status">
                                <svg class="inline m-2 w-12 h-12 text-gray-200 animate-spin dark:text-primary fill-primary"
                                    viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                        fill="currentColor" />
                                    <path
                                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                        fill="currentFill" />
                                </svg>
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                        <button *ngIf="!this.updateLoader" (click)="toggleEditModal(null)"
                            class="h-12 px-6 py-2.5 bg-secondary text-white font-medium text-lg leading-tight font-bold mt-7 shadow-md hover:bg-[#1d4e4b] hover:shadow-lg focus:bg-[#1d4e4b] focus:shadow-lg focus:outline-none focus:ring-0 active:bg-[#1d4e4b]   active:shadow-lg transition duration-150 ease-in-out m-2">
                            Fermer
                        </button>
                        <button *ngIf="!this.updateLoader" type="submit"
                            class="h-12 px-6 py-2.5 bg-[#339886] text-white font-medium text-lg leading-tight font-bold mt-7 shadow-md hover:bg-[#1d4e4b] hover:shadow-lg focus:bg-[#1d4e4b] focus:shadow-lg focus:outline-none focus:ring-0 active:bg-[#1d4e4b]   active:shadow-lg transition duration-150 ease-in-out m-2">
                            Mettre à jour
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </div>

    <div *ngIf="deleteModal" class="modal-info-inline p-3">
        <h2 class="text-2xl my-4">Attention</h2>
        <p class="text-xl">
            Souhaitez vous vraiment supprimer cet utilisateur ?
        </p>

        <div *ngIf="errorModal != ''" class="bg-red-200 text-red-700 mt-3 p-5 justify-center items-center rounded mb-0">
            {{ this.errorModal }}
        </div>
        <br>
        <div *ngIf="successModal != ''"
            class="bg-green-200 text-green-700 my-3 p-5 justify-center items-center rounded">
            {{ this.successModal }}
        </div>
        <div *ngIf="successModal === ''">
            <div *ngIf="this.removeLoader" class="w-full flex justify-center flex-wrap px-4 sm:px-6 md:p-2 m-2">
                <div role="status">
                    <svg class="inline m-2 w-12 h-12 text-gray-200 animate-spin dark:text-primary fill-primary"
                        viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="currentColor" />
                        <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="currentFill" />
                    </svg>
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
            <button *ngIf="!this.removeLoader" (click)="toggleDeleteModal(null)"
                class="h-12 px-6 py-2.5 bg-secondary text-white font-medium text-lg leading-tight font-bold mt-7 shadow-md hover:bg-[#1d4e4b] hover:shadow-lg focus:bg-[#1d4e4b] focus:shadow-lg focus:outline-none focus:ring-0 active:bg-[#1d4e4b]   active:shadow-lg transition duration-150 ease-in-out m-2">
                Fermer
            </button>
            <button *ngIf="!this.removeLoader" (click)="deleteAgent()"
                class="h-12 px-6 py-2.5 bg-red-500 text-white font-medium text-lg leading-tight font-bold mt-7 shadow-md hover:bg-red-500 hover:shadow-lg focus:bg-red-500 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-red-500   active:shadow-lg transition duration-150 ease-in-out m-2">
                Supprimer
            </button>
        </div>
    </div>
</div>